import { createContext } from 'solid-js';
import { gql } from '../graphql';
import { cachedQuery } from '../graphql/cached-get';
import type { CardQuery, TroonCardSubscriptionProduct } from '../graphql';
import type { Accessor } from 'solid-js';

export const OldCardCtx = createContext<Accessor<CardQuery | undefined>>(() => undefined);

export const AccessProductsCtx = createContext<Accessor<Array<TroonCardSubscriptionProduct>>>(() => []);

const query = gql(`query subscriptionProducts {
  products: troonCardSubscriptionProducts {
    id
    active
    subscriptionName
    description
    type
    amount {
      value
      code
      displayValue
    }
    valueProps
    isPopular
  }
}`);

export const getAccessProducts = cachedQuery(query);
